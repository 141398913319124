
<div class="row" style="margin-top: 5%">



	<div class="table-responsive">
		<table id="example" class="table table-striped table-bordered dt-responsive nowrap" style="width:100%">
			<thead class="thead-dark">
				<tr>
					<th>Sociéte Debitrice</th>
					<th>Gerant</th>
					<th>Email</th>
					<th>Mot de passe</th>
					<th>Ville</th>
					<th>Telephone</th>

				</tr>
			</thead>
			<tbody>
				<tr>
					<th scope="row">{{debiteur.societe_debitrice}}</th>
					<td>{{debiteur.gerant}}</td>
					<td>{{debiteur.email}}</td>
					<td>{{debiteur.password}}</td>
					<td>{{debiteur.ville}}</td>
					<td>{{debiteur.telephone}}</td>

				</tr>	
			</tbody>
		</table>
	</div>


	<div class="table-responsive" style="margin-top: 5%">
		<table id="example" class="table table-striped table-bordered dt-responsive nowrap" style="width:100%">
			<thead class="thead-dark">
				<tr>
					<th scope="col">Dette(s)</th>
					<th scope="col">Réclamé</th>
					<th scope="col">Reconnu</th>
					<th scope="col">Versé</th>
					<th scope="col">Solde</th>
					<!--<th scope="col">Date dernier versement</th>-->
					<th scope="col">Date prochain versement</th>
					<th scope="col">Détails</th>
				</tr>
			</thead>
			<tbody>

				<tr *ngFor="let dette of deb_dettes">
					<th scope="row">{{dette.intitule}}</th>
					<td>{{dette.montant_reclame | number}} FCFA</td>
					<td>{{dette.montant_reconnu | number}} FCFA</td>
					<td>{{dette.montant_verse | number}} FCFA</td>
					<td>{{(dette.montant_reconnu - dette.montant_verse) | number}} FCFA</td>
					<!--<td>{{dette.dernier_versement | date:'mediumDate'}} </td>-->
					<td>{{dette.date_echeance_mensuelle | date:'mediumDate'}} </td>
					<td> <button class="btn btn-primary" (click)="detailDebt(dette.id)">Details</button> </td>
				</tr>

			</tbody>
		</table>
	</div>














