import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
//import { BoardUserComponent } from '../board-user/board-user.component';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
//import { filter, map } from 'rxjs/operators';


@Component({
	selector: 'app-detail-dette',
	templateUrl: './detail-dette.component.html',
	styleUrls: ['./detail-dette.component.css']
})
export class DetailDetteComponent implements OnInit {

	id:any;
	dette_details:any;

	constructor(
		private UserService:UserService,
		private route: ActivatedRoute,

		) { }

	ngOnInit(): void {
		this.route.queryParams
		.subscribe(params => { 
			this.id = params;
			console.log(this.id);
		}
		);

		this.detailDebt(this.id.id);
	}

	detailDebt(id:number){
		this.UserService.getDebDetail(id).subscribe((dette_details: any) =>{
			this.dette_details = dette_details;
			console.log(this.dette_details);

		})
		console.log('detail de la dette')
	}

}



