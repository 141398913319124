<div class="container" *ngIf="!isLoggedIn">
	<header class="jumbotron">
		<h2>DEBITEUR</h2>
	</header>
</div>

<div class="container" *ngIf="isLoggedIn">
	<header class="jumbotron">
		<h2>Bienvenu Mr {{debiteur.gerant}}</h2>
	</header>
</div>
