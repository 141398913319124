import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-board-user',
  templateUrl: './board-user.component.html',
  styleUrls: ['./board-user.component.css']
})
export class BoardUserComponent implements OnInit {
  //content?: string;
  debiteur :  any;
  deb_dettes: any;
  deb_id:any = this.tokenStore.getUser().id; 

  constructor(private userService: UserService, 
    private tokenStore:TokenStorageService,
    private router:Router) { }

  ngOnInit(): void {

    this.debiteur = this.tokenStore.getUser();
    this.dettesDeb();
    console.log(this.debiteur)
  }

  //recup des dettes du debiteur
  dettesDeb(){
    this.userService.getDebDette(this.deb_id).subscribe((dettes : any) => {
      this.deb_dettes = dettes;
      console.log (this.deb_dettes);
    }
    )}

    detailDebt(dette_id:number){
      this.router.navigate(['/detail'], { queryParams: { id: dette_id } })
    }


  }

