import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { TokenStorageService } from '../_services/token-storage.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  //content?: string;
  debiteur:any;
  isLoggedIn = false;
  

  constructor(private userService: UserService, private tokenStore:TokenStorageService) { }

  ngOnInit(): void {


    //recup infos debiteur en session 
    this.debiteur = this.tokenStore.getUser();
    //recup token en session
    this.isLoggedIn = !!this.tokenStore.getToken();
    

   /* this.userService.getPublicContent().subscribe(
      data => {
        this.content = data;
      },
      err => {
        this.content = JSON.parse(err.error).message;
      }
      );*/
    }


  }
