import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../_services/token-storage.service';

const API_URL = 'https://api.arcreances.com/dettes/';
const API_URL_detail = 'https://api.arcreances.com/recus/';

//const API_URL = 'http://api-arc.centremedical-lysportiques.com/dettes/';
//const API_URL_detail = 'http://api-arc.centremedical-lysportiques.com/recus/'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, private tokenStore: TokenStorageService) { }

  
  //recup dettes debiteurs
  getDebDette(deb_id: number){
    return this.http.get(API_URL + deb_id);
  }

  //recup  details de la dette
  getDebDetail(dette_details_id: number){
    return this.http.get(API_URL_detail + dette_details_id);
  }
}
